import { useSelector } from "react-redux";
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import {
	Home,
	Login,
	NotFound,
	Robots,
	Toy,
	GoodsDetail,
	Monsters,
	DisembodiedHeads,
	Kittens,
	Humans,
	ShoppingCart
} from "./pages";
import styles from "./App.module.css";
import { Layout } from "./components";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
	const login = useSelector((state) => state.login.login);
	const PrivateRoute = ({ children }) => {
		const { pathname } = useLocation();
		return login ? (
			children
		) : (
			<Navigate to={"/login"} state={{ returnURL: pathname }} />
		);
	};

	return (
		<div className={styles.app}>
			<BrowserRouter>
				<Layout>
					<Routes>
						<Route path={"/"} element={<Navigate to={"/toy"} />} />
						<Route path={"/about"} element={<Navigate to={"/toy"} />} />
						<Route path={"/contact"} element={<Navigate to={"/toy"} />} />
						<Route path={"/language"} element={<Navigate to={"/toy"} />} />
						<Route path={"/home"} element={<Home />} />
						<Route
							path={"/toy"}
							element={
								<PrivateRoute>
									<Toy />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/goodsDetail"}
							element={
								<PrivateRoute>
									<GoodsDetail />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/disembodied-heads"}
							element={
								<PrivateRoute>
									<DisembodiedHeads />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/kittens"}
							element={
								<PrivateRoute>
									<Kittens />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/humans"}
							element={
								<PrivateRoute>
									<Humans />
								</PrivateRoute>
							}
						/>
						<Route
							path={"/shopping-cart"}
							element={
								<PrivateRoute>
									<ShoppingCart />
								</PrivateRoute>
							}
						/>
						<Route path={"/login"} element={<Login />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Layout>
			</BrowserRouter>
		</div>
	);
}

export default App;
