import React from "react";
import { useNavigate, NavLink, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { log_out } from "../../store/action/signin.action.js";
import styles from "./Header.module.css";
import { Navbar, Nav, Container, NavDropdown, Row, Col } from 'react-bootstrap';
export const Header = () => {
  const navigate = useNavigate();
  const login = useSelector((state) => state.login.login);
  const shopping = useSelector((state) => state.shopping.shopping);
  const dispatch = useDispatch();
  const { path, pathname } = useLocation();

  const list = [
    "/robots",
    "/monsters",
    "/disembodied-heads",
    "/kittens",
    "/humans",
  ];
  const list2 = [
    {
      link: '/toy',
      title: 'Toy'
    },
    {
      link: '/about',
      title: 'About'
    },
    {
      link: '/contact',
      title: 'Contact'
    },
    {
      link: '/language',
      title: 'Language'
    },
  ]
  const handleLogout = () => {
    dispatch(log_out());
    return <Navigate to={"/login"} state={path} />;
  };
  return (
    <>
      {/* <Navbar expand="lg" className={styles.navbar}> */}
      <img src="/img/多工具.svg" style={{
         position: 'fixed',
         right: '4vw',
         top:'1vw',
         height:'6vw',
         zIndex:999999999
      }} />
      <nav className={styles.navbar +' navbar-expand  '}   >
        <div>   
          <div class={styles.navWrap}  >
            <div className={styles.nav+'   '}  > 
            <a href="/" style={{textDecoration:'none',borderBottom:'none'}} >AI Spea</a>
             <p >
              {list2.map((item, index) => {
                  return (
                    <>
                    <a href={login ? item.link : "/login"} key={'nav_link_' + index} className={(pathname === item.link ? styles["current-menu"] : "normor-menu")+' normor-menu nav-link'}>{item.title}</a>
                      
                    </>
                  );
                })}
             </p>
             
            
            </div></div>


          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={styles.nav} >
              {list2.map((item, index) => {
                return (
                  <>
                    <Nav.Link href={login ? item.link : "/login"} className={pathname === item.link ? styles["current-menu"] : "normor-menu"}
                      key={'nav_link_' + index} >{item.title}</Nav.Link>
                  </>
                );
              })}

            </Nav>
          </Navbar.Collapse> */}


        </div>
      </nav>

      {/* </Navbar> */}

      {/* <div className={styles.header}>
      <div className={styles["header-content"]}>
        <div
          className={styles.logo}
          onClick={() => {
            navigate("/home");
          }}
        > 
          <h3>AI Spea</h3>
        </div>
        <ul>
          {list.map((item, index) => {
            return (
              <li
                className={pathname === item ? styles["current-menu"] : ""}
                key={index}
              >
                <NavLink to={login ? item : "/login"}>
                  {item.slice(1, 2).toUpperCase() + item.slice(2)}
                </NavLink>
              </li>
            );
          })}
        </ul> 
      </div>
    </div> */}
    </>
  );
};
