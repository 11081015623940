import React, { useEffect, useState } from "react";
import { GoodsList ,Banner } from "../../components"; 
import styles from "./Toy.module.css";
import { Container, Row, Col } from "react-bootstrap";
export const Toy = (props) => {
	const [aboutCardList, setAboutList] = useState([]);
	const [news, setNews] = useState([]);

	useEffect(() => {
		setAboutList([
			{
				name: 'Emotional Support',
				icon: '/img/xin_zang.svg',
				desc: 'Emotional and Safety Support',
				detail: 'Alwaus there to comfort and protect, ourdevice is a steadast companion for yourchild, providing peace of mind forparents with advanced safety features.',
			},
			{
				name: 'Social Development',
				icon: '/img/maozi.svg',
				desc: 'Social skills andFamily Engagement',
				detail: 'Building communication skills throughplauful interaction, while fostering strongfamily bonds with activities thatencourage emotonal expression and understanding.',
			},
			{
				name: 'Educational Growvth',
				icon: '/img/xin_zang.svg',
				desc: 'Educational and Cuniosity Enhancement',
				detail: 'Lgnitingg mninds with interactive learning. our smart device answers questionsacross subjects, spurring curiosity withfun challenges and educational games.',
			}
		])
		setNews([
			{
				coverImg:"/img/tt.png",
				title: 'Emotional Support', 
				time: '2024.06.18',
				detail: 'Beijing Info Tech Univ hosts campus open day, launches integrated education college; "Smart Bisi Rabbit" becomes promo ambassador.',
			},
			{
				title: 'Social Development',
				coverImg: '/img/fabuhui.jpg',
				time: '2024.02.12',
				detail: 'The Shahe Higher Education Park Student Innovation and Entrepreneurship Investment Alliance was established, and the inaugural "Beijing Bright Start" ...',
			},
			 
		])
	}, []);
	return (
		<>
		   <Banner />
				 
			<GoodsList title={"Toy"} sets={""} />
		 

		 
					<div style={{ width: '90%', minWidth: '700px',margin:'0 auto' }}>
						<div className={styles.xinwen}>

							<div className={styles.neirong}>
								<Container>
									<Row>
										{aboutCardList.map(it => {

											return <Col className={styles.aboutUsCard}>
												<span >
													{it.name}
												</span>
												<div>
													<img src={it.icon} />
												</div>
												<h5>{it.desc}</h5>
												<p>{it.detail}</p>
											</Col>

										})}

									</Row>
								</Container>
							</div>
							<div className={styles.youYuan}>
								About Us
							</div>
							<div className={styles.yuangroup}>
								<div> </div>
								<div> </div>
								<div> </div>
								<div> </div>
								<div> </div>
								<div> </div>
								<div> </div>
								<div> </div>
							</div>
						</div>
						<div className={styles.xinwen2}>
							<div className={styles.neirong2Wrap}>
							   <p>
										Latest News
									</p>
								<div className={styles.neirong2}>
									 
									<Container>
										<Row>
											{news.map(it => { 
												return <Col
												xs="6"
												sm="6"
												md="6"
												lg="6"
												xl="6"
												xx="6"
												className={styles.aboutUsCard2}>
													<div className={styles.newsCoverImg}
													 style={{backgroundImage:`url(${it.coverImg})`}}
													> </div>
													<span>{it.title}</span>
													<h5>{it.time}</h5>  
													<p>{it.detail}</p>
												</Col>

											})}

										</Row>
									</Container>
								</div>
							</div>
 
						</div>
					</div>
				 
			<div className={styles.footer}>
				<Container className={styles.footerContainer} >
					<Row  >
						<div>
							<div className={styles.chanRaoXianWrap}>
								<img src="/img/chan_rao_footer.svg" className={styles.chanRaoXian} />
								<img src="/img/da_xiang.svg" className={styles.dx} />
								<img src="/img/da_xiang.svg" className={styles.dx} />
								<img src="/img/da_xiang.svg" className={styles.dx} />
							</div>
							<div className={styles.footerKuang}> </div>
							<div className={styles.footerTitle}> Take smart，play smart ! </div>
							<div className={styles.footerContent}>
								<div>
									<img src="/img/erweima.png" />
									<div>Official Account</div>
								</div>
								<div>
									<div>Contact Us</div>
									<p>business@aispea.com </p>
									<p>+86 156 0057 7129 </p>
								</div>
								<div>
									<div>Product Categories</div>
									<p>Ai plush toy</p>
									<p>Ai walkie-talkie</p>
									<p>Ai figure stand</p>
								</div>
								<div>
									<div>Learn more about us</div>
									<p>X-AiSpea43643</p>
									<p>tiktok-aispeatoys</p>
									<p>instagram-tiktok</p>
								</div>
							</div>
							<div className={styles.copyrighttext}>
								<div  >
									<img src="/img/淘宝图标页脚.svg" style={{marginRight:'20px'}}/>
									<img src="/img/ins 图标 页脚.svg" /> 
									<div  >
										©Copyright 2024 AiSpea, All rights Reserved.
									</div>
								</div>

							</div>
						</div>
					</Row></Container>
			</div>


		</>
	);
};
