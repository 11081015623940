import {useDispatch,useSelector} from 'react-redux'
import styles from './ShoppingCart.module.css'

export const ShoppingCart = () => {
	const shopping = useSelector((state) => state.shopping.shopping)
	const dispatch = useDispatch()
	return <div className={styles['shopping-cart']}>
	{shopping.length===0?
		<div className={styles.empty}>
			<p>Your shopping cart is empty...</p>
		</div>:
		<>
			<div className={styles.header} >
				<h3>Shopping Cart({shopping.length})</h3>
			</div>
			<div className={styles['count-card']}>
				<p>
					<label>Select All<input type="checkbox" className={styles.checkbox} /></label>
				</p>
				<p>
					total:{}
				</p>
			</div>
			<ul className={styles.list}>
				{shopping.map((item,index)=>(
					<li key={index}>
						{item.discount!==1?
							<p className={styles.discount}>Discount</p>:
							null
						}
						<span className={styles['btn-delete']}>×</span>
						<img src={`https://robohash.org/${item.id}${item.sets}`} width='210' height='210' />
						<div className={styles.discription}>
							<h5 className={styles.name} title={item.name}>{item.name}</h5>	
							<p>Detail：qwertyuiop</p>
							{item.discount===1?
								<div className={styles.price}>price：${(item.price).toFixed(2)}</div>:
								<div className={styles.price}>
									<p className={styles['del-price']}><del>price：${(item.price).toFixed(2)}</del></p>
									<p>Price：${(Math.floor(item.price*item.discount*100)/100).toFixed(2)}</p>
								</div>
							}
							<p>Author：Nicholas</p>
						</div>
						<input type="checkbox" 
							className={styles.checkbox}
							checked={item.isChecked}
							onChange={()=>{dispatch({type:'modify_shopping', payload:index })}}
						/>
					</li>
				))}
			</ul>
		</>
	}	
	</div>
}