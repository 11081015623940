import React, { useEffect, useState } from "react";
import axios from "axios";
import Card from "../Card/Card.js";
import styles from "./List.module.css";

export function List({ title, sets }) {
  const urlString = (title) => {
    if (title === "Disembodied Heads") {
      return "disembodied-heads";
    }
    return title.toLowerCase();
  };
  const url = urlString(title);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [cardProps, setCardProps] = useState({});

  const fetchData = async () => {
    setLoading(true);
    const ret = await axios.get(`/${url}`).then((res) => res.data.list);
    setUsers([...users, ...ret]);
    // console.log(users);
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = async ({ id, name, sets, price, discount }) => {
    setShowCard(true);
    setCardProps({
      id,
      name,
      sets,
      price,
      discount,
    });
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className={styles.robots}>
        <h3>{title}</h3>
        {loading ? (
          <div className={styles.loading}>loading...</div>
        ) : (
          <ul>
            {users.map((item) => (
              <li
                key={item.id}
                onClick={() =>
                  handleClick({
                    id: item.id,
                    name: item.name,
                    sets: sets,
                    price: item.price,
                    discount: item.discount,
                  })
                }
              >
                {item.discount !== 1 && (
                  <p className={styles.discount}>Discount</p>
                )}
                {/* <img
                  src={`https://robohash.org/${item.id}${sets}`}
                  alt={title}
                  width="200"
                  height="200"
                /> */}
                <img src={item.image} alt="图片" />
                <h5>{item.name}</h5>
                {item.discount === 1 ? (
                  <div className={styles.price}>
                    price：${item.price.toFixed(2)}
                  </div>
                ) : (
                  <div className={styles.price}>
                    <p className={styles["del-price"]}>
                      <del>price：${item.price.toFixed(2)}</del>
                    </p>
                    <p>
                      Price：$
                      {(
                        Math.floor(item.price * item.discount * 100) / 100
                      ).toFixed(2)}
                    </p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
        {showCard ? (
          <Card cardProps={cardProps}>
            <span
              className={styles["close-card"]}
              onClick={() => setShowCard(false)}
            >
              ×
            </span>
          </Card>
        ) : null}
        <p onClick={fetchData} className={styles["loading-more"]}>
          点击可加载更多...
        </p>
      </div>
    </>
  );
}
