import {combineReducers} from 'redux'
import signinReducer from './signin.reducer'
import userReducer from './users.reducer'
import shoppingReducer from './shopping.reducer'

const reducer = combineReducers({
	login:signinReducer,
	users:userReducer,
	shopping:shoppingReducer
})

export default reducer