import {createAction} from 'redux-actions'

export const load_users = createAction('load_users')
export const load_users_success = createAction('load_users_success')

export const load_robots = createAction('load_robots')
export const load_robots_success = createAction('load_robots_success')

export const load_monsters = createAction('load_monsters')
export const load_monsters_success = createAction('load_monsters_success')

export const load_kittens = createAction('load_kittens')
export const load_kittens_success = createAction('load_kittens_success')

export const load_humans = createAction('load_humans')
export const load_humans_success = createAction('load_humans_success')

export const load_disembodied_heads = createAction('load_disembodied_heads')
export const load_disembodied_heads_success = createAction('load_disembodied_heads_success')