import { useDispatch, useSelector } from 'react-redux'
import styles from './Card.module.css'

const Card = ({children,cardProps:{id,name,sets,price,discount}}) => {
	const dispatch = useDispatch()
	const shopping = useSelector(state => state.shopping.shopping)

	const handleClick = ({ id, name, sets, price, discount }) => {
		if (shopping.find(item => item.id === id&&item.sets===sets)) {
			alert('此商品已在购物车中')
			return
		}
		dispatch({type:'add_to_shopping',payload:{id,name,sets,price,discount,isChecked:false}})
	}
	return (
		<div className={styles.wrap}>
			<div className={styles.card}>
				{children}
				{discount!==1?<p className={styles.discount}>Discount</p>:null}
				<h5>{name}</h5>
				<img
					src={`https://robohash.org/${id}${sets}`}
					title={name}
					alt={name}
					draggable="false"
				/>
				<p className={styles.price}>Price：${Math.floor(price * discount * 100) / 100}</p>
				<p>
					<button
						className={styles['add-btn']}
						onClick={() => handleClick({ id, name, sets, price, discount })}
					>
						Add to shopping cart
					</button>
				</p>
			</div>
		</div>
	);
}

export default Card