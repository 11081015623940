import {
	handleActions
} from 'redux-actions'
import {
	load_users_success,
	load_robots_success,
	load_monsters_success,
	load_kittens_success,
	load_disembodied_heads_success,
	load_humans_success
} from '../action/users.action.js'

const initialState = {
	users: [{
			id: 1,
			price: 12,
			name: 'Sharon Taylor',
			image: 'http://dummyimage.com/200',
			discount: 0.8
		},
		{
			id: 2,
			price: 10,
			name: 'Eric Clark',
			image: 'http://dummyimage.com/200',
			discount: 1
		},
		{
			id: 3,
			price: 7,
			name: 'Brian Robinson',
			image: 'http://dummyimage.com/200',
			discount: 0.8
		},
		{
			id: 4,
			price: 7,
			name: 'David Lopez',
			image: 'http://dummyimage.com/200',
			discount: 0.8
		},
		{
			id: 5,
			price: 3,
			name: 'Donald Hernandez',
			image: 'http://dummyimage.com/200',
			discount: 1
		},
		{
			id: 6,
			price: 11,
			name: 'Barbara Anderson',
			image: 'http://dummyimage.com/200',
			discount: 0.7
		},
		{
			id: 7,
			price: 16,
			name: 'Susan Clark',
			image: 'http://dummyimage.com/200',
			discount: 0.7
		},
		{
			id: 8,
			price: 9,
			name: 'Deborah Jones',
			image: 'http://dummyimage.com/200',
			discount: 0.8
		}
	],
	robots: [],
	monsters: [],
	kittens: [],
	disembodied_heads: [],
	humans: []
}
const userReducer = handleActions({
	[load_users_success]: (state, action) => ({
		users: [...state.users, ...action.payload]
	}),
	[load_robots_success]: (state, action) => ({
		robots: [...state.robots, ...action.payload]
	}),
	[load_monsters_success]: (state, action) => ({
		monsters: [...state.monsters, ...action.payload]
	}),
	[load_kittens_success]: (state, action) => ({
		kittens: [...state.kittens, ...action.payload]
	}),
	[load_disembodied_heads_success]: (state, action) => ({
		disembodied: [...state.disembodied, ...action.payload]
	}),
	[load_humans_success]: (state, action) => ({
		humans: [...state.humans, ...action.payload]
	}),
}, initialState)

export default userReducer
