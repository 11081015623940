import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from "react";
import styles from './Banner.module.css'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const Banner = () => {
    const [deg, setDeg] = useState(0);
    window.addEventListener('scroll',  () =>{ 
        // if(window.scrollY<=600){ 
        //         setDeg((window.scrollY/600)*30) 
        //         console.log(deg)
        //  }else{

        //  }
    });

    return (
        <>
            <div className={styles.bg}>
                {/* <Container>
                    <Row className={styles.bgImg}>
                    </Row>
                </Container> */}
                <div className={styles.bgImg}>

                </div>
                
                <div className={styles.stLeftGroup} style={{transform:`rotate(-${deg}deg)`}}>
                    <img src='/img/h4.svg' width={178} height={279} />
                </div>
                <div className={styles.stRightGroup} style={{transform:`rotate(${deg}deg)`}}>
                    <img src='/img/h3.svg' width={159} height={240} />
                    <img src='/img/h2.svg' width={177} height={330} /> 
                </div>
                
            </div>
            <div style={{position:'relative'}}>
               <div className={styles.mainTitle}>
                <div className={styles.titleLineTop}>
                Take smart,play smart! 
                <div className={styles.indexIcons}>
                    <img src='/img/爱心图标首页.svg' />
                    <img src='/img/机器人图标首页.svg' />
                    <img src='/img/聊天图标首页.svg' />
                </div>
               
                </div>
                    <img src='/img/da_xiang.svg' style={{
                        position:'absolute',
                    left:'5vw',
                    bottom:'-7vw',
                    width:'20vw'
                    }} />
                </div>
            </div>
            {/* <Container>
                <Row  > 
                    <div className={styles.borderXiong}> 
                    </div>
                </Row>
            </Container> */}
        </>

    );
} 