import {createStore,applyMiddleware} from 'redux'
import createSagaMiddle from 'redux-saga'
import usersSaga from './saga/users.saga'
import reducer from './reducer'

const saga=createSagaMiddle()

// const store = createStore(reducer)
const store = createStore(reducer,applyMiddleware(saga))

saga.run(usersSaga)

export default store