import React, { useEffect, useState } from "react";
import { useNavigate, NavLink, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import Card from "../GoodsCard/GoodsCard.js";
import styles from "./List.module.css";
import { Container, Row } from "react-bootstrap";

export function GoodsList({ title, sets }) {
    const urlString = (title) => {
        if (title === "Disembodied Heads") {
            return "disembodied-heads";
        }
        return title.toLowerCase();
    };
    const navigate = useNavigate();
    const url = urlString(title);
    const [goodsListData, setGoodsListData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [cardProps, setCardProps] = useState({});

    const fetchData = async () => {
        setLoading(true);
        const ret = await axios.get(`/goodsListData`).then((res) => res.data.list);
        setGoodsListData(ret);
        // console.log(users);
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);

    const handleClick = async ({ id, name, sets, price, discount }) => {
        setShowCard(true);
        setCardProps({
            id,
            name,
            sets,
            price,
            discount,
        });
    };

    const contentWrapRender = (item, flag) => {
        return <>
            <div className={styles.contentWrap}
                onClick={() => {
                    navigate("/goodsDetail")
                }}
            >
                <div className={styles.titleWrap}>
                    <div className={styles.title}>Penuin</div>
                    <div className={styles.icon_group}>
                        <img src="/img/xin_zang.svg" />
                        <img src="/img/ji_qi_ren.svg" />
                        <img src="/img/liao_tian_kuang.svg" />
                    </div>
                    <div className={styles.cleanFloat}></div>
                </div>
                <div className={styles.lou_kong}>Ai Plush toy</div>
                <p className={styles.contentText}>
                    Our interactive plush toy, perfect for children aged 3 to 6, sparks curiosity, entertains, and provides comforting companionship, enriching play and learning moments.</p>
                <div className={styles.buyNow + (flag ? (' ' + styles.buyNow2) : '')}>
                    <img src="/img/cart_fill.svg" />
                    MORE!
                </div>
            </div>
        </>
    }

    const imgsRender = (item, flag) => {
        return <>
            <div className={styles.imgs + ' ' + (flag ? styles.zuotubj : styles.youtubj)}>
                <div style={{ backgroundImage: 'url(/img/goods_1.png)' }} className={(flag ? styles.zuotucp : styles.youtucp)} ></div>
            </div>
        </>
    }

    useEffect(() => { }, []);
    return (
        <>
            <div style={{ width: '90%', minWidth: '700px' }} className={styles.robots}>

                {/* <Container>
                <Row className={styles.robots}> */}
                {loading ? (
                    <div className={styles.loading}>loading...</div>
                ) : (
                    <ul>
                        {goodsListData.map((item, index) => (<>

                            <li
                                key={item.id}
                                className={styles.cardWrap}

                                onClick={() =>
                                    handleClick({
                                        id: item.id,
                                        name: item.name,
                                        sets: sets,
                                        price: item.price,
                                        discount: item.discount,
                                    })
                                }
                            >
                                {
                                    ((index + 1) % 2 != 0 ? <>
                                        <img src="/img/chan_rao_xian.svg" style={{ width: '40vw', right: '10vw', position: 'absolute' }} />
                                    </>
                                        : <>
                                            <img src="/img/chan_rao_xian_2.svg" style={{ width: '70vw', position: 'absolute' }} />
                                        </>)
                                }
                                <div className={styles.hotsale + ' ' + ((index + 1) % 2 != 0 ? styles.zuohotsale : styles.zuohotsale + ' ' + styles.youhotsale)}>Hot Sale!</div>
                               {index==0?<>
                                <img src="/img/gs.svg" style={{
                                    position: 'absolute',
                                    top: '-14vw',
                                    right: 0
                                }} />
                               </>:null}
                                {(index + 1) % 2 != 0 ? <>

                                    {contentWrapRender(item, false)}
                                    {imgsRender(item, false)}
                                </> :
                                    <>

                                        {imgsRender(item, true)}
                                        {contentWrapRender(item, true)}

                                    </>
                                }


                            </li>
                            {
                                (index + 1) % 3 === 0 ? <>
                                    <li key={item.id}
                                        className={styles.cardWrap + ' ' + styles.zhuanshu}
                                        onClick={() =>
                                            handleClick({
                                                id: item.id,
                                                name: item.name,
                                                sets: sets,
                                                price: item.price,
                                                discount: item.discount,
                                            })
                                        }>
                                        <div className={styles.createYouOwn + ' ' + styles.createYouOwnpersonalizedToyTexT}>
                                            Create you own
                                        </div>
                                        <div className={styles.personalizedToy + ' ' + styles.createYouOwnpersonalizedToyTexT}>
                                            personalized toy
                                        </div>
                                        <div className={styles.imgs + ' ' + styles.youtubj}>
                                            <div style={{ backgroundImage: 'url(/img/goods_1.png)' }} className={styles.youtucp} ></div>
                                        </div>
                                        <div className={styles.icons_group}>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                            <div className={styles.useicons} style={{ backgroundImage: 'url(/img/goods_1.png)' }}></div>
                                        </div>
                                        <div className={styles.buyNow}
                                            onClick={() => {
                                                navigate("/goodsDetail")
                                            }}
                                        >
                                            <img src="/img/cart_fill.svg" />
                                            Go Now
                                        </div>
                                    </li>
                                </> : null
                            }
                        </>
                        ))}
                    </ul>
                )}
                {/* {showCard ? (
                            <Card cardProps={cardProps}>
                                <span
                                    className={styles["close-card"]}
                                    onClick={() => setShowCard(false)}
                                >
                                    ×
                                </span>
                            </Card>
                        ) : null} */}
                {/* <p onClick={fetchData} className={styles["loading-more"]}>
                        查看更多...
                    </p> */}
                {/* </Row>
            </Container> */}
            </div>
        </>
    );
}
