import {takeEvery,put} from 'redux-saga/effects'
import axios from 'axios'
import { load_users,load_users_success,
		load_robots,load_robots_success,
		load_monsters,load_monsters_success,
		load_kittens,load_kittens_success,
		load_disembodied_heads,load_disembodied_heads_success,
		load_humans,load_humans_success,
	} from '../action/users.action.js'

function* load_user_data(action){
	const usersData=yield axios.get("/robots").then(res=>res.data.list)
	yield put(load_users_success(usersData))
}

function* load_robots_data(action){
	const data=yield axios.get("/robots").then(res=>res.data.list)
	yield put(load_robots_success(data))
}

function* load_kittens_data(action){
	const data=yield axios.get("/kittens").then(res=>res.data.list)
	yield put(load_kittens_success(data))
}

function* load_disembodied_heads_data(action){
	const data=yield axios.get("/disembodied_heads").then(res=>res.data.list)
	yield put(load_disembodied_heads_success(data))
}

function* load_humans_data(action){
	const data=yield axios.get("/humans").then(res=>res.data.list)
	yield put(load_humans_success(data))
}

function* load_monsters_data(action){
	const data=yield axios.get("/monsters").then(res=>res.data.list)
	yield put(load_monsters_success(data))
}

export default function* usersSaga(){
	yield takeEvery(load_users,load_user_data)
	yield takeEvery(load_robots,load_robots_data)
	yield takeEvery(load_kittens,load_kittens_data)
	yield takeEvery(load_disembodied_heads,load_disembodied_heads_data)
	yield takeEvery(load_humans,load_humans_data)
	yield takeEvery(load_monsters,load_monsters_data)
}