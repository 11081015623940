import { handleActions } from "redux-actions";
import { add_to_shopping, modify_shopping } from "../action/shopping.action.js";

const initialState = {
	shopping: [],
};

const shoppingReducer = handleActions(
	{
		[add_to_shopping]: (state, action) => {
			if (state.shopping.length === 0) {
				return { shopping: [action.payload] };
			}
			return { shopping: [...state.shopping, action.payload] };
		},
		[modify_shopping]: (state,action) => {
			const index = action.payload
			const shopping = JSON.parse(JSON.stringify(state.shopping))
			shopping[index].isChecked = !shopping[index].isChecked
			return {shopping}
		}
	},
	initialState
);

export default shoppingReducer;
