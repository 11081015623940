import React, { useEffect, useState } from "react";
import styles from "./GoodsDetail.module.css";
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import { Container, Row, Table } from "react-bootstrap";

export const GoodsDetail = () => {
    const [curr, setCurr] = useState(0)
    const [bannerHeight, setBannerHeight] = useState('480px')
    const [details, setDetails] = useState({
        goodsName: 'Emotional Support',
        coverImgs: [
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
            '/img/goods_banner_main.png',
        ],
        desc: 'Alwaus there to comfort and protect, ourdevice is a steadast companion for yourchild, providing peace of mind forparents with advanced safety features.',
    });
    const [teXing, setTeXing] = useState([
        {
            name: 'Wi-Fi Enabled',
            icon: '/img/goods_detail_wifi.svg'
        },
        {
            name: 'Ages 3+',
            icon: '/img/goods_detail_star.svg'
        },
        {
            name: 'Unlimited Talk',
            icon: '/img/goods_detail_chat.svg'
        },

        {
            name: 'Educational Support',
            icon: '/img/goods_detail_mao_zi.svg'
        },

        {
            name: 'Screen-Free,\nEye-Friendly',
            icon: '/img/goods_detail_xin.svg'
        },

        {
            name: 'Entertainment Support',
            icon: '/img/goods_detail_yin_yue.svg'
        },
    ]);
    useEffect(() => {
        
      }, []);
      window.scrollTo(0, 0);


    return <>
        <div style={{width:'80vw',margin:'0 auto',minWidth:'1200px'}}>
        <div style={{ marginTop: '10px', }}>
                    {/* 上部 */}
                    <div className={styles.top}>
                        {/* 图片展示 */}
                        <div className={styles.topLeft}>
                            <Carousel variant="dark"
                                data-ride="carousel"
                                prevIcon={<>
                                <img src="/img/prev_banner.svg" />
                                </>}
                                nextIcon={<>
                                    <img src="/img/next_banner.svg" />
                                    </>}
                                data-interval="2000"
                                data-bs-theme="dark" style={{ height: bannerHeight }}>
                                {details.coverImgs.map(it => {
                                    return (<Carousel.Item
                                        style={{
                                            height: bannerHeight,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            backgroundImage: `url(${it})`
                                        }}
                                    >
                                    </Carousel.Item>)
                                })}
                                 
                            </Carousel>

                        </div>

                        {/* 商品详情 */}
                        <div className={styles.topRight}>
                            <h1>
                                {details.goodsName}
                            </h1>
                            <h2>{details.desc}</h2>
                            <div className={styles.rightIcon}>

                                {teXing.map((it => {
                                    return <>
                                        <div className={styles.iconBg}>
                                            <div
                                                className={styles.icon}
                                                style={{
                                                    backgroundImage: `url(${it.icon})`
                                                }}> </div>
                                            <div className={styles.iconName}>{it.name}</div>
                                        </div>
                                    </>
                                }))}

                            </div>
                            <div className={styles.operationBtns}>
                                {/* 操作按钮 */}
                                <div style={{
                                    backgroundSize: 'cover',
                                    backgroundImage: `url("/img/add_to_shopping_cart.svg")`
                                }}>
                                </div>

                                <div className={styles.xxx} >
                                    <img width="60px" src="/img/goto_shopping_cart.svg" />
                                </div>

                                <div style={{ userSelect: 'none' }} >Shop Now !</div>
                            </div>
                        </div>
                        <img className={styles.fangChat} src="/img/fang_chat.svg" />
                    </div>


                    {/* 下部 */}
                    <div className={styles.mybottom}>
                        <div className={styles.bg}></div>
                        <div className={styles.biaoYu}>
                            <p> Play, Laugh, and Learn </p>
                            <p> --ALL IN AI CHAT! </p>
                        </div>
                        <div className={styles.morex}>
                            MORE
                        </div>
                        <div className={styles.boxs}>
                            
                            <div className={styles.box1}
                                style={{
                                    overflow: 'hidden',
                                    backgroundImage: 'url(/img/goos_main_img.png)'
                                }}
                            >
                                
                                                            </div>
                            <div className={styles.box2}
                                style={{
                                    overflow: 'hidden',
                                    backgroundImage: 'url(/img/goods_params.png)',
                                    backgroundPosition:'0 0'
                                }}
                            > 
                                <Table className={styles.mytable}> 
                                    <tbody>
                                        <tr> 
                                            <td colSpan={2}>Product Specifications</td>
                                        </tr>
                                        <tr>
                                            <td>Brand</td>
                                            <td>AiSpea</td>
                                        </tr>
                                        <tr>
                                            <td>Name</td>
                                            <td>AI plush toy</td>
                                        </tr>
                                        <tr>
                                            <td>Material</td>
                                            <td>Plush</td>
                                        </tr>
                                        <tr>
                                            <td>Availability Status</td>
                                            <td>In Stock</td>
                                        </tr>
                                        <tr>
                                            <td>Recommended Age</td>
                                            <td>Ages 3 +</td>
                                        </tr>
                                        <tr>
                                            <td>Product Includes</td>
                                            <td>1.Plush Toy with Artificial Intelligence
                                                Conversation Module
                                                2.1200mAh Rechargeable Battery
                                                3.USB-C Charging Cable</td>
                                        </tr>
                                        <tr>
                                            <td>Manufacturer's
                                                Address</td>
                                            <td>Beijing CyberTronics Technology Co., Ltd.
                                                11/F, North Tower, Beijing Kerry Centre,
                                                No. 1 Guanghua Road, Chaoyang District,
                                                Beijing, China.
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </div>
                </div>

        </div>
      

    </>
}