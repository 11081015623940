import React from 'react'
import styles from './NotFound.module.css'

export const NotFound = () => {
	return(
		<div className={styles['not-found']}>
			<p>404 Not Found...</p>	
		</div>
	)
}
